<!--采购进场计量明细表-->
<template>
    <div class="PurchaseBalanceDetails table-bg">
        <reportTable
            code="PurchaseBalanceDetails"
            :query="query"
            @generateComplete="generateComplete"
            @exportTable="exportTable"
            custom-export
            :percentage="percentage"
            date-label="进场日期"
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">出场日期：</label>
                    <el-date-picker
                        v-model="tareTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        @change="changeTareTime"
                        :clearable="true"
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_ids"
                        placeholder="请选择"
                        clearable
                        multiple
                        collapse-tags
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料分类：</label>
                    <el-select
                        v-model="query.type_dic_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialChild"
                    >
                        <el-option
                            v-for="item in materialGroupList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料子类：</label>
                    <el-select
                        v-model="query.child_type_code"
                        placeholder="请选择"
                        clearable
                        @change="getMaterialSpec"
                    >
                        <el-option
                            v-for="item in materialChildList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <label class="name">原材料规格：</label>
                    <el-select
                        v-model="query.spec_code"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in materialSpecList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="query-item">
                    <el-input placeholder="过磅编码/车牌号码/供应商" v-model="query.search_key"></el-input>
                </div>
            </template>
            <template #customTable>
                <u-table
                    ref="virtualTable"
                    :data="tableList"
                    :height="height"
                    :row-height="40"
                    row
                    use-virtual
                    border
                    v-loading="loading"
                >
                    <u-table-column
                        v-for="(item,index) in tableHeader"
                        :key="'col'+index"
                        :label="item.label"
                        :prop="item.prop"
                        :width="item.width"
                    >
                    </u-table-column>
                </u-table>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import qs from 'qs';
import utils from '@/utils/util';
import { export_json_to_excel } from '@/assets/js/exportExcel';
export default {
    components: { reportTable },
    data() {
        const tableHeader = [
            {
                label: '场站',
                prop: 'station_name',
                width: '100',
            }, {
                label: '过磅编码',
                prop: 'balance_number',
                width: '190',
            }, {
                label: '车牌号码',
                prop: 'plate_number',
                width: '100',
            }, {
                label: '进场时间',
                prop: 'data_date',
                width: '160',
            }, {
                label: '出场时间',
                prop: 'tare_time',
                width: '160',
            }, {
                label: '物料',
                prop: 'spec_name',
                width: '190',
            }, {
                label: '供应商',
                prop: 'supplier_name',
                width: '200',
            }, {
                label: '进场计量',
                prop: 'gross_weight',
                width: '100',
            }, {
                label: '出场计量',
                prop: 'tare_weight',
                width: '100',
            }, {
                label: '实际净重',
                prop: 'adjusted_net_weight',
                width: '100',
            }, {
                label: '原发数量',
                prop: 'delivery_weight',
                width: '100',
            }, {
                label: '税率',
                prop: 'tax_rate',
                width: '100',
            }, {
                label: '直接材料单价',
                prop: 'adjusted_unit_price',
                width: '130',
            }, {
                label: '直接材料金额',
                prop: 'adjusted_money',
                width: '130',
            }, {
                label: '不含税金额',
                prop: 'no_tax_money',
                width: '110',
            }, {
                label: '运输单价',
                prop: 'adjusted_transport_price',
                width: '100',
            }, {
                label: '运输金额',
                prop: 'adjusted_transport_money',
                width: '100',
            }, {
                label: '采购金额',
                prop: 'purchase_money',
                width: '100',
            }, {
                label: '入库数量',
                prop: 'already_count',
                width: '100',
            }, {
                label: '是否对账',
                prop: 'account_statement_status',
                width: '100',
            },
        ];
        return {
            loading: false,
            query: {
                startTareTime: '',
                endTareTime: '',
                station_ids: [],
                type_dic_code: '',
                child_type_code: '',
                spec_code: '',
                search_key: '',
            },
            tareTime: [],
            stationList: [],
            materialGroupList: [],
            materialChildList: [],
            materialSpecList: [],

            tableTitle: '采购进场计量明细表',
            tableHeader: tableHeader,
            totalData: {},
            tableList: [],
            // 时间分批大小 ：天
            limit: 10,
            // 进度条
            percentage: 100,
            // 查询分组
            queryArr: null,
            height: 0,
        };
    },
    created() {
        this.getStationList();
        this.getMaterialGroup();
    },
    mounted() {
        let resize = null;
        window.addEventListener('resize', () => {
            resize = this.setBodyHeight(resize);
        });
        this.setBodyHeight(resize);
    },
    methods: {
        setBodyHeight(resize) {
            clearInterval(resize);
            const timer = setTimeout(() => {
                this.height = document.querySelector('.table-box').clientHeight;
                if (this.$refs.virtualTable) {
                    this.$refs.virtualTable.doLayout();
                }
            }, 50);
            return timer;
        },
        changeTareTime(val) {
            if (val) {
                this.query.startTareTime = val[0];
                this.query.endTareTime = val[1];
            } else {
                this.query.startTareTime = '';
                this.query.endTareTime = '';
            }
        },
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 原材料分类
        async getMaterialGroup() {
            this.materialGroupList = await this.$axios.get('/interfaceApi/procurement/material/group/type/get');
        },
        // 原材料子类
        async getMaterialChild() {
            this.query.child_type_code = '';
            this.query.spec_code = '';
            this.materialChildList = [];
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/child/get/parent_code?parent_code=${this.query.type_dic_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialChildList = data;
            }
        },
        // 原材料规格
        async getMaterialSpec() {
            this.query.spec_code = '';
            this.materialSpecList = [];
            const url = `/interfaceApi/procurement/material/group/type/spec/get/parent_code?parent_code=${this.query.child_type_code}`;
            const data = await this.$axios.get(url);
            if (data.length > 0) {
                this.materialSpecList = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.queryArr = null;
                this.loading = true;
                this.tableList = [];
                this.mergeCells = {};
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    pam.station_ids = pam.station_ids.join(',');
                    const listUrl = `/interfaceApi/report/purchase_balance_details_report/data_list?${qs.stringify(pam)}`;
                    const list = await this.$axios.get(listUrl);
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    this.tableList.push(...list);
                    if ( Number(i) === 0) {
                        this.loading = false;
                    }
                }
                if (this.tableList.length > 0) {
                    // 获取合计数据
                    const url = `/interfaceApi/report/purchase_balance_details_report/data_total?${qs.stringify(params)}`;
                    const totalData = await this.$axios.get(url);
                    totalData.data_date = '';
                    totalData.station_name = '合计';
                    this.tableList.push(totalData);
                }

                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        exportTable() {
            const data = [];
            const merges = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: this.tableHeader.length - 1 } },
            ];
            const header1 = new Array(this.tableHeader.length).fill('');
            header1[0] = this.tableTitle;
            const header2 = this.tableHeader.map(item => item.label);
            this.tableList.forEach(row => {
                const arr = [];
                this.tableHeader.forEach(item => {
                    arr.push(row[item.prop]);
                });
                data.push(arr);
            });
            const header = [
                header1,
                header2,
            ];
            export_json_to_excel({
                multiHeader: header,
                merges: merges,
                data,
                filename: this.tableTitle,
            });
        },
    },

    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>
</style>


